import styled from "styled-components";
import Button from "./Button";
import { useEffect, useState } from "react";
import AssetSelectorPopup from "./AssetSelectorPopup";
import { AssetData } from "../app/helpers/get-asset-data";
import useTokenBalances from "../app/web3/views/use-token-balances";
import { ScaledNumber } from "scaled-number";

import selectionIndicator from "../assets/ui/selection-indicator.svg";
import round from "../app/helpers/round";
import useTokenMetadata from "../app/web3/views/use-token-metadata";

interface ContainerProps {
  $hasIcon: boolean;
  $error: boolean;
}

const Container = styled.div<ContainerProps>`
  display: flex;
  width: 100%;
  height: 4rem;
  align-items: center;
  padding: 0.9rem 1.6rem;
  padding-left: ${(props) => (props.$hasIcon ? "0.8rem" : "1.6rem")};
  border: solid 1px ${(props) => (props.$error ? "var(--error)" : "var(--sub)")};
  border-radius: var(--border-radius);
  position: relative;
  background: var(--bg-1);
  margin-bottom: ${(props) => (props.$error ? "2rem" : "0")};
`;

const Icon = styled.img`
  height: 100%;
  margin-right: 0.8rem;
`;

const StyledInput = styled.input`
  height: 100%;
  flex: 1;
  font-size: 1.4rem;
  font-weight: 500;
  color: var(--main);

  &::placeholder {
    color: var(--sub);
  }

  @media (max-width: 900px) {
    width: 100%;
  }
`;

const ButtonContainer = styled.div`
  margin-left: 0.8rem;
`;

const Subtext = styled.div`
  position: absolute;
  bottom: -2rem;
  font-size: 1.2rem;
  font-weight: 500;
  color: var(--error);
`;

const TokenContainer = styled.button`
  display: flex;
  align-items: center;
  padding: 0 0.8rem;
  height: 2.4rem;
  border-radius: 1.5rem;
  background: var(--bg-3);
  cursor: pointer;
  margin-right: 0.8rem;
`;

const TokenIcon = styled.img`
  height: 1.4rem;
  margin-right: 0.8rem;
`;

const TokenText = styled.div`
  font-size: 1.4rem;
  font-weight: 500;
  color: var(--main);
`;

const SelectionIndicator = styled.img`
  height: 0.6rem;
  margin-left: 0.6rem;
`;

const UsdContainer = styled.div`
  margin-top: 0.8rem;
  display: flex;
  align-items: center;
  gap: 0.8rem;
  width: 100%;
`;

const UsdAmount = styled.div`
  color: var(--sub);
  font-size: 1.2rem;
  font-weight: 500;
`;

interface Props {
  value: string;
  setValue: (value: string) => void;
  placeholder?: string;
  icon?: string;
  max?: number;
  number?: boolean;
  error?: string;
  token?: string;
  tokenOptions?: string[];
  setToken?: (token: string) => void;
  price?: number;
  minAmount?: number;
}

const Input = ({
  value,
  setValue,
  placeholder,
  icon,
  max,
  number,
  error,
  token,
  tokenOptions,
  setToken,
  price,
  minAmount,
}: Props) => {
  const allTokenMetadata = useTokenMetadata();

  const [settingToken, setSettingToken] = useState(false);
  if (!!value && max !== undefined && Number(value) > max) {
    error = "Amount exceeds balance";
  }
  if (
    !!value &&
    price !== undefined &&
    minAmount !== undefined &&
    Number(value) * price < minAmount
  ) {
    error = `Min mint amount is $${minAmount}`;
  }

  const tokenAddresses =
    tokenOptions && allTokenMetadata
      ? tokenOptions.map((id: string) => {
          const assetMetadata = allTokenMetadata[id];
          if (!assetMetadata) {
            throw new Error(`Asset metadata not found for ${id}`);
          }
          return assetMetadata.address;
        })
      : undefined;

  const tokenBalances = useTokenBalances(tokenAddresses);

  const assetsOptions =
    tokenOptions && allTokenMetadata
      ? tokenOptions.map((id: string) => {
          const assetMetadata = allTokenMetadata[id];
          if (!assetMetadata) {
            throw new Error(`Asset metadata not found for ${id}`);
          }
          const assetData: AssetData = {
            id,
            icon: assetMetadata.icon,
            name: assetMetadata.name,
            symbol: assetMetadata.symbol,
            volume: assetMetadata.marketCap || 0,
            color: "pink",
            balance: tokenBalances
              ? tokenBalances[assetMetadata.address]
              : undefined,
            address: assetMetadata.address,
          };
          return assetData;
        })
      : null;

  // Set token to one with highest balance
  const shouldSetHighest = !!tokenBalances && !!assetsOptions;
  useEffect(() => {
    if (!setToken) return;
    if (!shouldSetHighest) return;

    const highestBalance = assetsOptions.reduce(
      (highest: AssetData, asset: AssetData) =>
        asset.balance &&
        asset.balance.gt(highest.balance || ScaledNumber.fromUnscaled(0))
          ? asset
          : highest,
      assetsOptions[0]
    );

    setToken(highestBalance.id);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldSetHighest]);

  const canSelectToken = tokenOptions && setToken && token && assetsOptions;
  const tokenMetadata =
    token && allTokenMetadata ? allTokenMetadata[token] : null;
  if (canSelectToken) {
    if (!tokenMetadata) {
      throw new Error(`Token metadata not found for ${token}`);
    }
  }

  return (
    <>
      <Container
        $hasIcon={!!icon || !!(canSelectToken && tokenMetadata)}
        $error={!!error}
      >
        {icon && <Icon src={icon} />}
        {canSelectToken && tokenMetadata && (
          <TokenContainer onClick={() => setSettingToken(true)}>
            <TokenIcon
              src={tokenMetadata.icon}
              alt={`${tokenMetadata.symbol} Icon`}
            />
            <TokenText>{tokenMetadata.symbol}</TokenText>
            <SelectionIndicator src={selectionIndicator} />
          </TokenContainer>
        )}
        <StyledInput
          type={number ? "number" : "text"}
          autoFocus
          value={value}
          onChange={(e) => setValue(e.target.value)}
          placeholder={placeholder}
        />
        {max !== undefined && (
          <ButtonContainer>
            <Button action={() => setValue(max.toString())} small>
              Max
            </Button>
          </ButtonContainer>
        )}
        {error && <Subtext>{error}</Subtext>}
      </Container>
      {price !== undefined && (
        <UsdContainer>
          <UsdAmount>≈</UsdAmount>
          <UsdAmount>
            ${value ? round(price * Number(value), 2).toLocaleString() : 0}
          </UsdAmount>
        </UsdContainer>
      )}
      {canSelectToken && (
        <AssetSelectorPopup
          isOpen={settingToken}
          setIsOpen={setSettingToken}
          options={assetsOptions}
          setActive={setToken}
        />
      )}
    </>
  );
};

export default Input;
