import { ScaledNumber } from "scaled-number";
import styled from "styled-components";
import { CardFrame } from "../../styles/Frames";
import Button from "../../components/Button";
import useMetadataForId from "../../app/web3/views/use-metadata-for-id";
import useBlockExplorerLink from "../../app/web3/utils/use-block-explorer-link";

const StyledReward = styled(CardFrame)`
  width: 100%;
  display: flex;
  padding: 2.4rem 3.1rem;

  @media (max-width: 900px) {
    flex-direction: column;
    padding: 1.6rem;
  }
`;

const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 3;
`;

const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  flex: 2;
  height: 100%;

  @media (max-width: 900px) {
    margin-top: 1.6rem;
  }
`;

const Header = styled.div`
  font-size: 2.4rem;
  font-weight: 400;
  color: var(--sub);
`;

const AmountContainer = styled.div`
  display: flex;
  align-items: flex-end;
`;

const Amount = styled.div`
  font-size: 4rem;
  font-weight: 400;
  margin-right: 0.8rem;
`;

const Unit = styled.div`
  font-size: 1.6rem;
  font-weight: 400;
  color: var(--sub);
  margin-bottom: 0.5rem;
`;

const IconLink = styled.a`
  cursor: pointer;
`;

const Icon = styled.img`
  height: 6.5rem;
  margin-bottom: 2rem;
  border-radius: 50%;

  @media (max-width: 900px) {
    display: none;
  }
`;

const Price = styled.div`
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.6rem;
  color: var(--sub);
  margin-top: 0.4rem;
`;

interface Props {
  assetKey: string;
  price?: ScaledNumber | null;
  amount?: string;
  claim: () => Promise<void>;
}

const RewardCard = ({ assetKey, price, amount, claim }: Props) => {
  const tokenMetadata = useMetadataForId(assetKey);
  const blockExplorerLink = useBlockExplorerLink();
  const value = new ScaledNumber(
    Number(amount || "0").toString(),
    tokenMetadata?.decimals
  );

  return (
    <StyledReward>
      <LeftColumn>
        <Header>{tokenMetadata?.symbol} Rewards</Header>
        <AmountContainer>
          <Amount>{amount ? value.toCryptoString() : "---"}</Amount>
          <Unit>{tokenMetadata?.symbol ?? "---"}</Unit>
        </AmountContainer>
        {price && (
          <Price>{amount ? value.toUsdValue(price.toNumber()) : "$---"}</Price>
        )}
      </LeftColumn>
      <RightColumn>
        <IconLink
          href={blockExplorerLink(tokenMetadata?.address)}
          target="_blank"
        >
          <Icon
            src={tokenMetadata?.icon}
            alt={`${tokenMetadata?.symbol} Icon`}
          />
        </IconLink>
        <Button
          primary
          web3
          wide
          action={() => {
            claim();
          }}
          disabled={value.isZero()}
        >
          Claim rewards
        </Button>
      </RightColumn>
    </StyledReward>
  );
};

export default RewardCard;
