import styled from "styled-components";

import { ScaledNumber } from "scaled-number";
import Reward from "./Reward";
import useLockerData from "../../app/web3/views/use-locker-data";
import useLocker from "../../app/web3/contracts/use-locker";
import useTlxPrice from "../../app/web3/views/use-tlx-price";
import useAmmApr from "../../app/web3/views/use-amm-apr";
import useEarnedVelo from "../../app/web3/views/use-earned-velo";
import useVeloPrice from "../../app/web3/views/use-velo-price";
import useVelodromeGauge from "../../app/web3/contracts/use-velodrome-gauge";
import { useDispatch } from "react-redux";
import { setError } from "../../state/uiSlice";
import { useEthers } from "@usedapp/core";
import { useNavigate } from "react-router-dom";
import {
  REFERRALS_DOCS,
  TLX_VELODROME_LP,
  TLX_YIELD_SERVER,
  VELODROME_YIELD_SERVER,
} from "../../app/constants/urls";
import { LOCK_PATH, STAKE_PATH } from "../../app/constants/paths";
import useStakerApr from "../../app/web3/views/use-staker-apr";
import useClaimableStakerRewards from "../../app/web3/views/use-claimable-staker-rewards";
import useStaker from "../../app/web3/contracts/use-staker";
import useReferrals from "../../app/web3/contracts/use-referrals";
import useReferralEarnings from "../../app/web3/views/use-referral-earnings";
import Seo from "../../components/Seo";
import OpIncentivesBanner from "../../components/OpIncentivesBanner";
import { PageHeader } from "../../styles/content";
import useChainData from "../../app/web3/utils/use-chain-data";
import RewardCard from "./RewardCard";
import useMultipleMerkleDistributor from "../../app/web3/contracts/use-multiple-merkle-distributor";
import useAccountRewards from "../../app/web3/views/use-account-rewards";
import { useMemo } from "react";

const StyledRewardsPage = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  padding: 4rem 0;
  max-width: 120rem;

  @media (max-width: 900px) {
    padding: 2rem;
  }
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(58rem, 1fr));
  grid-gap: 4rem;
  width: 100%;

  @media (max-width: 900px) {
    grid-template-columns: 1fr;
    grid-gap: 2rem;
  }
`;

const RewardsPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { account } = useEthers();
  const lockerData = useLockerData();
  const lockerContract = useLocker();
  const tlxPrice = useTlxPrice();
  const veloPrice = useVeloPrice();
  const ammApr = useAmmApr();
  const earnedVelo = useEarnedVelo();
  const veloGaugeContract = useVelodromeGauge();
  const stakerApr = useStakerApr();
  const claimableStakerRewards = useClaimableStakerRewards();
  const stakerContract = useStaker();
  const referralsContract = useReferrals();
  const referralEarnings = useReferralEarnings();
  const chainData = useChainData();
  const multipleMerkleDistributorContract = useMultipleMerkleDistributor();
  const {
    data: rewards,
    isLoading,
    refetch: refetchAccountRewards,
  } = useAccountRewards();

  const totalAmount = useMemo(
    () =>
      rewards
        ?.filter((reward) => !reward.hasClaimed)
        .reduce((curr, acc) => Number(acc.amount) + curr, 0),
    [rewards]
  );

  return (
    <StyledRewardsPage>
      <Seo
        title="TLX Protocol Rewards"
        description="Earn rewards when your referral code is used"
      />
      <OpIncentivesBanner />
      <PageHeader>Rewards</PageHeader>
      <Container>
        <RewardCard
          assetKey={chainData?.chainId === 10 ? "op" : "usdc"}
          amount={isLoading ? undefined : totalAmount?.toString()}
          claim={async () => {
            if (
              !(multipleMerkleDistributorContract && account && rewards?.length)
            )
              return;

            try {
              const claims = rewards
                .filter((reward) => !reward.hasClaimed)
                .map((reward) => ({
                  index: reward.index,
                  account: account,
                  amount: reward.amount,
                  merkleProof: reward.proof,
                  epoch: reward.epoch,
                }));

              const tx = await multipleMerkleDistributorContract.claimMultiple(
                claims
              );
              await tx.wait();
              refetchAccountRewards();
            } catch (e: any) {
              dispatch(
                setError({
                  message: e.message,
                  source: "Rewards/Claim",
                })
              );
            }
          }}
        />

        {!chainData?.v2 && (
          <Reward
            header="Trading rebates"
            tooltip="Earn rewards when your referral code is used. Or from trading rebates."
            assetKey="susd"
            price={ScaledNumber.fromUnscaled(1)}
            amount={referralEarnings}
            learnMoreLink={REFERRALS_DOCS}
            claim={async () => {
              if (!referralsContract) return;

              try {
                const tx = await referralsContract.claimEarnings();
                await tx.wait();
              } catch (e: any) {
                dispatch(
                  setError({
                    message: e.message,
                    source: "Rewards/Referrals/Claim",
                  })
                );
              }
            }}
            earn={() => {
              (window as any).open(REFERRALS_DOCS, "_blank")?.focus();
            }}
          />
        )}
        {earnedVelo !== null && !earnedVelo.isZero() && (
          <Reward
            header="TLX/ETH LP Rewards"
            tooltip="Rewards earned from providing liquidity to the TLX/ETH pool on Velodrome"
            assetKey="velo"
            price={veloPrice}
            amount={earnedVelo}
            apr={ammApr ? ammApr.toPercent() : "--%"}
            aprCodeLink={VELODROME_YIELD_SERVER}
            claim={async () => {
              if (!veloGaugeContract) return;
              if (!account) return;

              try {
                const tx = await veloGaugeContract.getReward(account);
                await tx.wait();
              } catch (e: any) {
                dispatch(
                  setError({
                    message: e.message,
                    source: "Rewards/LP/Claim",
                  })
                );
              }
            }}
            earn={() => window.open(TLX_VELODROME_LP, "_blank")?.focus()}
          />
        )}
        {claimableStakerRewards !== null &&
          !claimableStakerRewards.isZero() && (
            <Reward
              header="Staking rewards"
              tooltip="sUSD tokens earned from staking TLX in the TLX Staking contract"
              assetKey="susd"
              price={ScaledNumber.fromUnscaled(1)}
              amount={claimableStakerRewards}
              apr={stakerApr ? stakerApr.toPercent() : "--%"}
              aprCodeLink={TLX_YIELD_SERVER}
              claim={async () => {
                if (!stakerContract) return;

                try {
                  const tx = await stakerContract.claim();
                  await tx.wait();
                } catch (e: any) {
                  dispatch(
                    setError({
                      message: e.message,
                      source: "Rewards/Staking/Claim",
                    })
                  );
                }
              }}
              earn={() => {
                navigate(`/${STAKE_PATH}`);
              }}
            />
          )}
        {lockerData !== null && !lockerData.claimable.isZero() && (
          <Reward
            header="Locker Rewards"
            tooltip="TLX tokens earned from locking in the Genesis Locker. The reward period for this has now ended."
            assetKey="tlx"
            ended
            price={tlxPrice}
            amount={lockerData?.claimable || null}
            aprCodeLink={TLX_YIELD_SERVER}
            claim={async () => {
              if (!lockerContract) return;

              try {
                const tx = await lockerContract.claim();
                await tx.wait();
              } catch (e: any) {
                dispatch(
                  setError({
                    message: e.message,
                    source: "Rewards/Locker/Claim",
                  })
                );
              }
            }}
            earn={() => navigate(`/${LOCK_PATH}`)}
          />
        )}
      </Container>
    </StyledRewardsPage>
  );
};

export default RewardsPage;
