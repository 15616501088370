import styled from "styled-components";

import AssetSelector from "../../components/AssetSelector";
import { useEffect, useState } from "react";
import Switch from "../../components/Switch";
import { constants } from "ethers";

import Radio, { RadioOption } from "../../components/Radio";
import Input from "../../components/Input";
import Chart from "../../components/Chart";
import Button from "../../components/Button";
import useLeveragedTokenData from "../../app/web3/views/use-leveraged-token-data";
import { getAssetData } from "../../app/helpers/get-asset-data";
import { SubCardFrame } from "../../styles/Frames";
import { Splitter } from "../../styles/utils";
import useApprovedAmount from "../../app/web3/views/use-approved-amount";
import useLeveragedToken from "../../app/web3/contracts/use-leveraged-token";
import useToken from "../../app/web3/contracts/use-token";
import InputHeader from "../../components/InputHeader";
import getRoundedScaledNumber from "../../app/helpers/get-rounded-scaled-number";
import { useDispatch } from "react-redux";
import { setError } from "../../state/uiSlice";
import getSlippage from "../../app/helpers/get-slippage";
import { useNavigate, useSearchParams } from "react-router-dom";
import { PORTFOLIO_PATH } from "../../app/constants/paths";
import getLeveragedTokenSymbol from "../../app/helpers/get-leveraged-token-symbol";
import useReferralCodeStorage from "../../app/hooks/use-referral-code-storage";
import useReferralCode from "../../app/web3/views/use-referral-code";
import useReferrals from "../../app/web3/contracts/use-referrals";
import { formatBytes32String } from "ethers/lib/utils";
import useLeveragedTokenChart from "../../app/hooks/use-leveraged-token-chart";
import LoadingSpinner from "../../components/LoadingSpinner";
import useSupportedZapTokens from "../../app/web3/views/use-supported-zap-tokens";
import useTokenBalance from "../../app/web3/views/use-token-balance";
import useZap from "../../app/web3/contracts/use-zap";
import MintInfoSection from "./MintInfoSection";
import useMintAmountOut from "../../app/web3/views/use-mint-amount-out";
import useTokenPrice from "../../app/web3/views/use-token-price";
import InfoLine from "../../components/InfoLine";
import round from "../../app/helpers/round";
import {
  MAX_UINT256,
  PRICE_IMPACT_GAIN_THRESHOLD,
  PRICE_IMPACT_WARNING_THRESHOLD,
} from "../../app/constants/config";
import Seo from "../../components/Seo";
import LeveragedTokenAmount from "./LeveragedTokenAmount";
import MintingDisabled from "../../components/MintingDisabled";
import LocationWarning from "../../components/LocationWarning";
import OpIncentivesBanner from "../../components/OpIncentivesBanner";
import PAUSED_ASSETS from "../../app/constants/paused-assets";
import { PageHeader } from "../../styles/content";
import useAddresses from "../../app/web3/utils/use-addresses";
import useTokenMetadata from "../../app/web3/views/use-token-metadata";
import useMetadataForId from "../../app/web3/views/use-metadata-for-id";
import getTokenId from "../../app/data/get-token-id";
import useChainData from "../../app/web3/utils/use-chain-data";
import useChainId from "../../app/web3/utils/use-chain-id";
import useWrapper from "../../app/web3/contracts/use-wrapper";
import useFeedId from "../../app/web3/views/use-feed-id";
import getPriceUpdateData from "../../app/web3/utils/get-price-update-data";
import { ASSET_METADATA } from "../../app/data/asset-metadata";
import { ScaledNumber } from "scaled-number";
import useEstimateRewards from "../../app/hooks/use-estimate-rewards";
import { getRewardToken } from "../../app/helpers/leaderboard";
import useTvlLimitThreshold from "../../app/web3/views/use-tlv-limit-threshold";
import useMinMintAmount from "../../app/web3/views/use-min-mint-amount";

const DEFAULT_ASSET = "ETH";

const StyledMintPage = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  padding: 4rem;

  @media (max-width: 900px) {
    padding: 2rem;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100rem;
`;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (max-width: 900px) {
    padding: 0;
    background: none;
    border: none;
  }
`;

const Sections = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  grid-gap: 2.4rem;

  @media (max-width: 900px) {
    grid-template-columns: 1fr;
  }
`;

const Section = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2.4rem;
  height: 100%;
  grid-template-rows: 1fr min-content;
`;

const RewardSection = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2.4rem;
`;

const ExpectedReward = styled.div`
  flex: 1;
  font-size: 1.6rem;
  font-weight: 500;
  white-space: nowrap;

  @media (max-width: 900px) {
    font-size: 1.4rem;
  }
`;

const ChartContainer = styled(SubCardFrame)`
  position: relative;
  flex: 1;

  @media (max-width: 900px) {
    height: 20rem;
  }
`;

const NotEnoughData = styled.p`
  font-size: 1.6rem;
  color: var(--sub);
  text-align: center;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MintPage = () => {
  // Hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const addresses = useAddresses();
  const tokenMetadata = useTokenMetadata();
  const leveragedTokenDataSource = useLeveragedTokenData();
  const leveragedTokenData = leveragedTokenDataSource?.filter(
    (data) => data.isActive
  );
  const [searchParams] = useSearchParams();
  const { code } = useReferralCodeStorage();
  const referralCode = useReferralCode();
  const referralContract = useReferrals();
  const zapAssets = useSupportedZapTokens();
  const zap = useZap();
  const susdPrice = useTokenPrice(addresses?.SUSD);
  const chainData = useChainData();
  const chainId = useChainId();
  const wrapper = useWrapper();

  // Params
  const hasClaimedReferralCode = referralCode !== null && referralCode !== "";
  const hasCodeToClaim = !!code && chainData && !chainData.v2;
  const initialAssetId = searchParams.get("asset") || DEFAULT_ASSET;
  const initialIsLong = searchParams.get("direction")
    ? searchParams.get("direction") === "long"
    : true;
  const initialLeverage = searchParams.get("leverage") || "3";

  // State
  const [mintToken, setMintToken] = useState<string>("usdc");
  const [assetId, setAssetId] = useState<string>(initialAssetId);
  const [isLong, setIsLong] = useState<boolean>(initialIsLong);
  const [collateral, setCollateral] = useState<string>("");
  const defaultLeverage = {
    value: initialLeverage,
    label: `${initialLeverage}x`,
  };
  const [leverage, setLeverage] = useState<RadioOption>(defaultLeverage);

  // Data
  const mintTokenData = useMetadataForId(mintToken);
  const mintTokenPrice = useTokenPrice(mintTokenData?.address);
  const mintTokenBalance = useTokenBalance(mintTokenData?.address);
  const mintTokenContract = useToken(mintTokenData?.address);
  const data = leveragedTokenData?.find(
    (lt) =>
      lt.targetAsset === assetId &&
      lt.isLong === isLong &&
      lt.targetLeverage.toCryptoString() === leverage.value
  );
  const feedId = useFeedId(data?.marketId);
  const chart = useLeveragedTokenChart(data?.addr);
  const leveragedToken = useLeveragedToken(data?.addr || null);
  const tvlLimitThreshold = useTvlLimitThreshold(data?.addr || null);
  const minMintAmount = useMinMintAmount(data?.addr || null);
  const mintWithZap = mintToken !== "susd" && !!chainData && !chainData.v2;
  const mintWithWrapper = chainData && chainData.v2;
  const approvalAddress = mintWithZap
    ? zap?.address
    : mintWithWrapper
    ? wrapper?.address
    : leveragedToken?.address;
  const approvedAmount = useApprovedAmount(
    mintTokenData?.address,
    approvalAddress
  );
  const leverageOptions = chainData
    ? chainData.leveragedTokens[assetId]?.map((value) => {
        return {
          value: value.toString(),
          label: `${value}x`,
        };
      })
    : [];
  const assetOptions = leveragedTokenData
    ? leveragedTokenData
        .map((lt) => lt.targetAsset)
        .filter((value, index, self) => self.indexOf(value) === index)
    : [];
  const tokenName = getLeveragedTokenSymbol(
    assetId,
    Number(leverage.value),
    isLong,
    chainId
  );
  const mintAmount = getRoundedScaledNumber(collateral, mintTokenBalance);
  const { expectedRewards, isLoading: isLoadingExpectedRewards } =
    useEstimateRewards(mintAmount, leverage.value);
  const rewardToken = getRewardToken(chainData?.chainId);

  const approved =
    approvedAmount !== null &&
    mintTokenBalance !== null &&
    approvedAmount.gte(mintAmount);
  const slippage = getSlippage(Number(leverage.value));
  const expectedOut = useMintAmountOut(
    approvedAmount !== null && mintTokenBalance !== null ? mintAmount : null,
    mintTokenData?.address,
    mintWithZap,
    data?.addr || null,
    data?.exchangeRate || null
  );
  const priceImpact =
    mintTokenPrice &&
    data &&
    expectedOut &&
    susdPrice &&
    !mintAmount.isZero() &&
    !expectedOut.isZero()
      ? expectedOut
          .mul(data.exchangeRate.mul(susdPrice))
          .sub(mintAmount.mul(mintTokenPrice))
          .div(mintAmount.mul(mintTokenPrice))
      : null;

  const tokenOptions =
    zapAssets && tokenMetadata
      ? zapAssets.map((zapAssetAddress: string) => {
          const id_ = getTokenId(zapAssetAddress, tokenMetadata);
          if (!id_) throw new Error("Invalid zap asset");
          return id_;
        })
      : undefined;
  if (chainData && !chainData.v2 && tokenOptions) {
    tokenOptions.push("susd");
  }
  const hasOnlyOneLeverageOption =
    leverageOptions && leverageOptions.length === 1;

  const directionPrefix = hasOnlyOneLeverageOption
    ? `${defaultLeverage.value}x `
    : "";

  // Handling
  useEffect(() => {
    if (!chainId) return;
    setAssetId(initialAssetId);
  }, [initialAssetId, chainId]);

  // Reset leverage on network change
  useEffect(() => {
    if (!chainId) return;
    setLeverage({
      value: initialLeverage,
      label: `${initialLeverage}x`,
    });
  }, [chainId, initialLeverage]);

  const isOverTvlCap =
    chainData &&
    chainData.v2 &&
    ASSET_METADATA[assetId] &&
    !!ASSET_METADATA[assetId].tvlCap &&
    ASSET_METADATA[assetId].tvlCap !== undefined &&
    mintAmount &&
    data &&
    data.totalSupply
      .mul(data.exchangeRate)
      .add(mintAmount)
      .gt(ScaledNumber.fromUnscaled(ASSET_METADATA[assetId].tvlCap));

  const tokenTvl = data ? data.totalSupply.mul(data.exchangeRate) : null;
  const tvlBelowThreshold =
    tvlLimitThreshold && tokenTvl && tokenTvl.lt(tvlLimitThreshold);
  const isBelowMintLimit =
    tvlBelowThreshold &&
    minMintAmount &&
    mintAmount &&
    mintAmount.lt(minMintAmount);

  return (
    <StyledMintPage>
      <Seo
        title="Mint Leveraged Tokens"
        description="Mint Leveraged Tokens on Optimism to long or short ETH, BTC, SOL and 50+ more assets"
      />
      <LocationWarning />
      <OpIncentivesBanner />
      <Content>
        <PageHeader>Mint Leveraged Tokens</PageHeader>
        <Container>
          <Sections>
            <Section>
              <ChartContainer>
                {chart && <Chart chart={chart} />}
                <LoadingSpinner show={chart === null} />
                {chart === undefined && (
                  <NotEnoughData>
                    Insufficient data to display the chart at this time
                  </NotEnoughData>
                )}
              </ChartContainer>
              <Splitter>
                <MintInfoSection data={data} />
              </Splitter>
            </Section>
            <Section>
              <SubCardFrame>
                <Splitter>
                  <InputHeader
                    header="Asset"
                    tooltip="The target asset the leveraged token will track the price of"
                  />
                  <AssetSelector
                    options={assetOptions.map((asset) => getAssetData(asset))}
                    active={assetId}
                    setActive={setAssetId}
                  />
                </Splitter>
                <Splitter>
                  <InputHeader
                    header="Direction"
                    tooltip="The direction of the exposure to the target asset"
                  />
                  <Switch
                    switches={[
                      { label: `${directionPrefix}Long`, value: "long" },
                      {
                        label: `${directionPrefix}Short`,
                        value: "short",
                        comingSoon: !!chainData && !chainData.shortingEnabled,
                        isNew: !!chainData && chainData.chainId === 8453,
                      },
                    ]}
                    active={isLong ? "long" : "short"}
                    setActive={(active: string) => setIsLong(active === "long")}
                    big={hasOnlyOneLeverageOption}
                  />
                </Splitter>
              </SubCardFrame>
              <SubCardFrame>
                {!hasOnlyOneLeverageOption && (
                  <Splitter>
                    <InputHeader
                      header="Leverage"
                      tooltip="The target leverage of the leveraged token to mint"
                    />
                    {leverageOptions && (
                      <Radio
                        options={leverageOptions}
                        active={leverage}
                        setActive={(option: RadioOption) => setLeverage(option)}
                      />
                    )}
                  </Splitter>
                )}
                <Splitter>
                  <InputHeader
                    header="Mint Amount"
                    tooltip={`The amount of ${
                      mintTokenData?.symbol ?? "---"
                    } to use to mint ${data?.symbol ?? "---"}`}
                  />
                  <Input
                    number
                    value={collateral}
                    setValue={(value: string) => setCollateral(value)}
                    placeholder={`Enter amount`}
                    max={mintTokenBalance ? mintTokenBalance.toNumber() : 0}
                    tokenOptions={tokenOptions}
                    token={mintToken}
                    setToken={(mintToken_: string) => {
                      setCollateral("");
                      setMintToken(mintToken_);
                    }}
                    price={mintTokenPrice ? mintTokenPrice.toNumber() : 0}
                    minAmount={
                      tvlBelowThreshold && minMintAmount
                        ? minMintAmount.toNumber()
                        : undefined
                    }
                  />

                  <RewardSection>
                    <InputHeader
                      header="Estimated Rewards"
                      tooltip="Estimated rewards are based on volume generated by this mint and the estimated total volume in the current week"
                      noMargin
                    />
                    <ExpectedReward>
                      {isLoadingExpectedRewards
                        ? "-"
                        : `${round(
                            expectedRewards,
                            4
                          ).toLocaleString()} ${rewardToken}`}
                    </ExpectedReward>
                  </RewardSection>
                </Splitter>
              </SubCardFrame>
              <SubCardFrame>
                {mintWithZap && (
                  <InfoLine
                    label="Price Impact"
                    error={
                      !!priceImpact &&
                      priceImpact.toNumber() < -PRICE_IMPACT_WARNING_THRESHOLD
                    }
                    success={
                      !!priceImpact &&
                      priceImpact.toNumber() > PRICE_IMPACT_GAIN_THRESHOLD
                    }
                    tooltip={`When minting, your ${
                      mintTokenData?.symbol ?? "---"
                    } is swapped for sUSD to mint ${
                      data?.symbol || "---"
                    }. Swapping large amounts can cause price impact. To minimize price impact, consider swapping your ${
                      mintTokenData?.symbol ?? "---"
                    } for sUSD on 1inch or Odos, and then minting with sUSD.`}
                    value={
                      priceImpact
                        ? `${!priceImpact.isNegative() ? "+" : ""} ${round(
                            priceImpact.mul(100).toNumber(),
                            3
                          ).toString()}`
                        : "--"
                    }
                    unit="%"
                  />
                )}
                <LeveragedTokenAmount
                  assetId={assetId}
                  leverage={Number(leverage.value)}
                  isLong={isLong}
                  exchangeRate={data?.exchangeRate || null}
                  expectedOut={expectedOut}
                />
                <Button
                  wide
                  primary
                  web3
                  loading={
                    !!data &&
                    !!leveragedToken &&
                    !!mintTokenContract &&
                    !!mintTokenBalance &&
                    !mintAmount.isZero() &&
                    !!approved &&
                    data.hasPendingLeverageUpdate
                  }
                  disabled={
                    !data ||
                    mintAmount.isZero() ||
                    !expectedOut ||
                    !!isOverTvlCap ||
                    !!isBelowMintLimit
                  }
                  action={async () => {
                    if (!leveragedToken) return;
                    if (!data) return;
                    if (!mintTokenContract) return;
                    if (!mintTokenBalance) return;
                    if (!expectedOut) return;
                    if (!addresses) return;
                    if (!mintTokenData) return;

                    if (hasCodeToClaim && !hasClaimedReferralCode) {
                      if (!referralContract) return;
                      try {
                        const codeBytes = formatBytes32String(code);
                        const tx = await referralContract.setReferral(
                          codeBytes
                        );
                        await tx.wait();
                      } catch (e: any) {
                        dispatch(
                          setError({
                            message: e.message,
                            source: "Mint/ClaimReferralCode",
                          })
                        );
                      }
                      return;
                    }

                    if (!approved) {
                      try {
                        const tx = await mintTokenContract.approve(
                          approvalAddress,
                          MAX_UINT256
                        );
                        await tx.wait();
                      } catch (e: any) {
                        dispatch(
                          setError({
                            message: e.message,
                            source: "Mint/Approve",
                          })
                        );
                      }
                      return;
                    }

                    if (mintWithZap) {
                      if (!zap) return;
                      try {
                        const tx = await zap.mint(
                          mintTokenData.address,
                          data.addr,
                          mintAmount.value,
                          expectedOut.mul((1 - slippage).toString()).value
                        );
                        await tx.wait();
                        setCollateral("");
                        navigate(`/${PORTFOLIO_PATH}`);
                      } catch (e: any) {
                        dispatch(
                          setError({
                            message: e.message,
                            source: "Mint/MintZap",
                          })
                        );
                      }
                      return;
                    }

                    if (mintWithWrapper) {
                      if (!wrapper) return;
                      if (!feedId) return;

                      try {
                        const priceUpdateData = getPriceUpdateData(feedId);
                        const tx = await wrapper.mintWithUSDC(
                          leveragedToken.address,
                          mintAmount.value,
                          expectedOut.mul((1 - slippage).toString()).value,
                          priceUpdateData,
                          constants.HashZero
                        );
                        await tx.wait();
                        setCollateral("");
                        navigate(`/${PORTFOLIO_PATH}`);
                      } catch (e: any) {
                        dispatch(
                          setError({
                            message: e.message,
                            source: "Mint/MintWrapper",
                          })
                        );
                      }
                      return;
                    }

                    try {
                      const tx = await leveragedToken.mint(
                        mintAmount.value,
                        expectedOut.mul((1 - slippage).toString()).value
                      );
                      await tx.wait();
                      setCollateral("");
                      navigate(`/${PORTFOLIO_PATH}`);
                    } catch (e: any) {
                      dispatch(
                        setError({
                          message: e.message,
                          source: "Mint/Mint",
                        })
                      );
                    }
                  }}
                >
                  {PAUSED_ASSETS.includes(tokenName)
                    ? "Minting Paused"
                    : mintAmount.isZero()
                    ? "Enter an amount"
                    : !data
                    ? "Select Leveraged Token"
                    : hasCodeToClaim && !hasClaimedReferralCode
                    ? "Claim Referral Code"
                    : isOverTvlCap
                    ? "Over TVL Cap"
                    : !approved
                    ? `Approve ${mintTokenData?.symbol ?? "---"}`
                    : `Mint ${tokenName}`}
                </Button>
              </SubCardFrame>
            </Section>
          </Sections>
          <MintingDisabled />
        </Container>
      </Content>
    </StyledMintPage>
  );
};

export default MintPage;
