import { useQuery } from "@tanstack/react-query";
import {
  CURRENT_EPOCH,
  getEpoch,
  getLeaderboardUrl,
  getWeeklyRewardAmount,
} from "../helpers/leaderboard";

export interface LeaderboardItem {
  epoch_start: string;
  account: string;
  total_fees_paid: string;
  fees_paid_pct: string;
  fees_rank: string;
  volume: string;
  volume_rank: string;
  volume_pct: string;
}

const useLeaderboard = (network?: number) => {
  return useQuery({
    queryKey: ["leaderboard", network],
    queryFn: async () => {
      const url = getLeaderboardUrl(network);
      const response = await fetch(url);
      const data: LeaderboardItem[] = await response.json();
      const rewardAmount = getWeeklyRewardAmount(network);

      return data
        .map((item) => {
          const epochStart = new Date(item.epoch_start);
          const epoch = getEpoch(epochStart.getTime());

          const isEpochEnded = CURRENT_EPOCH.epoch > epoch.epoch;

          const estimatedRewards =
            epoch.epoch === 1
              ? Number(item.fees_paid_pct) * rewardAmount
              : isEpochEnded
              ? Number(item.volume_pct) * rewardAmount
              : Number(item.volume_pct) * rewardAmount * CURRENT_EPOCH.progress;

          return {
            account: item.account,
            date: epochStart,
            rank: Number(item.volume_rank),
            totalFeesPaid: Number(item.total_fees_paid),
            volume: Number(item.volume),
            volume_pct: Number(item.volume_pct),
            estimatedRewards,
            isEpochEnded,
          };
        })
        .sort((a, b) => (a.rank < b.rank ? -1 : 1));
    },
  });
};

export default useLeaderboard;
