import opWeek1 from "../../constants/distributions/OptimismLevTokenRewards/week1_output.json";
import opWeek2 from "../../constants/distributions/OptimismLevTokenRewards/week2_output.json";
import opWeek3 from "../../constants/distributions/OptimismLevTokenRewards/week3_output.json";
import baseWeek1 from "../../constants/distributions/BaseLevTokenRewards/week1_output.json";
import baseWeek2 from "../../constants/distributions/BaseLevTokenRewards/week2_output.json";
import baseWeek3 from "../../constants/distributions/BaseLevTokenRewards/week3_output.json";

const distributions = [
  opWeek1,
  opWeek2,
  opWeek3,
  baseWeek1,
  baseWeek2,
  baseWeek3,
];

export interface Reward {
  index: number;
  epoch: number;
  amount: string;
  proof: string[];
}

interface RewardDistribution {
  merkleRoot: string;
  tokenTotal: string;
  chainId: number;
  epoch: number;
  claims: {
    [key: string]: Omit<Reward, "epoch">;
  };
}

const getAccountReward = (
  distribution: RewardDistribution,
  account: string
): Reward => {
  const rewardKey = Object.keys(distribution.claims).find(
    (key) => key.toLowerCase() === account?.toLowerCase()
  );

  if (!rewardKey) {
    return {
      index: 0,
      amount: "0",
      proof: [],
      epoch: distribution.epoch,
    };
  }

  const claims = distribution.claims[rewardKey];
  return { ...claims, epoch: distribution.epoch };
};

export const getRewards = (account: string, chainId: number) => {
  const currentChainDistributions: RewardDistribution[] = distributions.filter(
    (distribution) => distribution.chainId === chainId
  );

  const rewards = currentChainDistributions.map((distribution) =>
    getAccountReward(distribution, account)
  );

  return rewards;
};
