import { useMemo } from "react";
import useChainData from "../web3/utils/use-chain-data";
import useLeaderboard from "./use-leaderboard";
import { getWeeklyRewardAmount, CURRENT_EPOCH } from "../helpers/leaderboard";
import { ScaledNumber } from "scaled-number";

const useEstimateRewards = (mintAmount: ScaledNumber, leverage: string) => {
  const chainData = useChainData();
  const { data, isLoading } = useLeaderboard(chainData?.chainId);

  const currentEpochList = useMemo(() => {
    return data?.filter((item) => !item.isEpochEnded);
  }, [data]);

  const expectedRewards = useMemo(() => {
    const totalRewardsPerWeek = getWeeklyRewardAmount(chainData?.chainId);
    const totalVolumeSoFarThisWeek = (currentEpochList || []).reduce(
      (curr, acc) => curr + acc.volume,
      0
    );

    const percentOfTheWayThroughTheWeek = CURRENT_EPOCH.progress;

    const volumeExpectedByEndOfWeek = percentOfTheWayThroughTheWeek
      ? totalVolumeSoFarThisWeek / percentOfTheWayThroughTheWeek
      : 0;

    const rewardsPerVolume = volumeExpectedByEndOfWeek
      ? totalRewardsPerWeek / volumeExpectedByEndOfWeek
      : 0;
    const expectedRewards =
      mintAmount.toNumber() * Number(leverage) * rewardsPerVolume;

    return expectedRewards;
  }, [chainData?.chainId, currentEpochList, leverage, mintAmount]);

  return {
    expectedRewards,
    isLoading,
  };
};

export default useEstimateRewards;
